<template>
  <div class="mt-5">
    <h5 v-if="detailCourse.minimum_completeness_criteria != null">
      <div class="d-flex align-items-center">
        <div class="col-md-4">
          <span>Nilai Kriteria Ketuntasan Minimal(KKM):</span>
        </div>
        <div class="col-md-8">
          <!-- <b-form-group
            id="input-group-name"
            label-for="input-name"
          > -->
            <b-form-input
              id="input-name"
              v-model="kkm.score"
              placeholder="Nilai Kriteria Ketuntasan Minimal(KKM)"
              disabled
            ></b-form-input>
            <!-- <small class="text-danger">{{ error.name }}</small> -->
          <!-- </b-form-group> -->
        </div>
      </div>
    </h5>
    <h5 v-if="detailCourse.minimum_completeness_criteria == null">
      Nilai Kriteria Ketuntasan Minimal belum diatur
    </h5>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
export default {
  props: {
    course: Object,
  },
  data() {
    return {
      detailCourse: [],
      kkm: [],
    };
  },
  methods: {
    async get() {
      this.detailCourse = await module.get(
        "api/courses/" + this.$route.params.id
      );
      //("detailCourse", this.detailCourse);
      this.kkm = this.detailCourse.minimum_completeness_criteria[0][0];
      //("kkmasdasdasdasadasddsa", this.kkm);
    },
  },
  mounted() {
    this.get();
  },
};
</script>

<style>
</style>