<template>
  <div class="animate__animated animate__fadeIn">
    <!-- Filter -->
    <div class="row justify-content-end mt-3">
      <div class="col-md-3">
        <treeselect
          v-model="filter.topic_id"
          :multiple="false"
          placeholder="Pilih Topik"
          :options="topics"
          @input="filterByTopic"
        />
      </div>
      <div class="col-md-3">
        <treeselect
          v-model="filter.question_type_id"
          :multiple="false"
          placeholder="Pilih Jenis Pertanyaan"
          :options="question_types"
          @input="filterByQuestionType"
        />
      </div>
      <div class="col-md-4">
        <b-input-group>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Saring Berdasar Judul"
            @keyup="filterByName"
          ></b-form-input>
          <template #append>
            <b-input-group-text squared class="bg-danger text-white rounded-0" style="cursor: pointer">
              <!-- <b-icon-search></b-icon-search> -->
              <span @click="filterReset">Reset</span>
            </b-input-group-text>
          </template>
        </b-input-group>
      </div>
      <div class="col-md-1 mr-8" v-if="btnAccess && purpose == 'master'">
        <b-button squared variant="primary" @click="handleContentAdd"
          >Tambah</b-button
        >
      </div>
    </div>
    <!-- table -->
    <div class="d-flex justify-content-end" v-if="purpose == 'modal-question'">
      <span> Total Nilai: {{totalValue}} </span>
    </div>
    <b-table
      striped
      hover
      :items="questions"
      :fields="purpose == 'master' ? fields : fieldsModal"
      class="mt-3"
      responsive
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{
            width: field.key === 'action' && purpose == 'master' ? '25%' : '',
          }"
        />
      </template>

      <template #cell(content)="data">
        <div v-html="data.item.content"></div>
      </template>

      <template #cell(mark)="data" v-if="purpose == 'modal-question'">
        {{data.item.mark != 0 ? data.item.mark : ''}}
      </template>

      <template #cell(action)="data">
        <template v-if="purpose == 'master'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="handleContentDetail(data.item.id)"
            ><i class="fas fa-eye px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="mr-1 btn-success"
            v-b-tooltip.hover
            title="Edit"
            @click="handleContentUpdate(data.item.id)"
            v-if="btnAccess"
            ><i class="fas fa-edit px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="btn-danger"
            v-b-tooltip.hover
            title="Hapus"
            @click="dataDelete(data.item.id)"
            v-if="btnAccess"
            ><i class="fas fa-trash px-0"></i
          ></b-button>
        </template>
        <template v-if="purpose == 'modal-question'">
          <b-form-checkbox
            :checked="data.item.checked"
            :id="`checkbox-pick-item-${data.item.id}`"
            :name="`checkbox-pick-item-${data.item.id}`"
            @change="
              chooseQuestion({
                id: data.item.id,
                title: data.item.title,
                content: data.item.content,
                question_type_name: data.item.question_type_name,
              }, data.item.checked)
            "
          >
          </b-form-checkbox>
        </template>
      </template>
    </b-table>

    <b-pagination
      v-if="questions.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import ApiService from "@/core/services/api.service.js";

export default {
  props: {
    purpose: String,
    selectedItem: Array,
    userData: Object,
    course: Object
  },
  data() {
    return {
      questions: [],
      content: null,
      perPage: 20,
      filter: null,
      totalRows: 1,
      currentPage: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        { key: "title", label: "Judul", sortable: true },
        // { key: "content", label: "Konten", sortable: true },
        {
          key: "topic_name",
          label: "Topik",
          sortable: true,
        },
        {
          key: "question_type_name",
          label: "Jenis Pertanyaan",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
        },
      ],
      fieldsModal: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        { key: "title", label: "Judul", sortable: true },
        { key: "content", label: "Konten", sortable: true },
        {
          key: "topic_name",
          label: "Topik",
          sortable: true,
        },
        {
          key: "question_type_name",
          label: "Jenis Pertanyaan",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
        },
        {
          key: "mark",
          label: "Nilai",
          tdClass: "nowrap"
        },
      ],
      // filter
      filter: {
        name: '',
        question_type_id: '',
        topic_id: ''
      },
      topics: [],
      question_types: [],
      // access
      btnAccess: false
    };
  },
  methods: {
    async getAllData() {
      let filterParams = `&name=${this.filter.name}&topic_id=${this.filter.topic_id}&question_type_id=${this.filter.question_type_id}`;
      let response = await module.paginate(
        `api/curriculums/questions/${this.course.course_id}`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.questions = response.data;
      this.setCheckedItem();
      this.setMark();
    },
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.getMaterial();
    },

    setCheckedItem() {
      if (this.selectedItem.length != 0 || this.selectedItem != "") {
        let found;
        this.questions.forEach((value) => {
          found = this.selectedItem.find((x) => x.id == value.id);
          if (typeof found === "undefined") {
            value.checked = false;
          } else {
            value.checked = true;
          }
        });
      }
    },

    setMark() {
      if (this.selectedItem.length != 0 || this.selectedItem != "") {
        for (let a = 0; a < this.questions.length; a++) {
          for (let b = 0; b < this.selectedItem.length; b++) {
            if (this.questions[a].id == this.selectedItem[b].id) {
              this.questions[a].mark = parseInt(this.selectedItem[b].mark);
            }
          }
        }
      }
      //("set questions with mark", this.questions);
    },

    chooseQuestion(value, checked) {
      if(checked == false){
        //("replace to true", value, checked)
        for(let b = 0; b < this.questions.length; b++){
          //("loop")
          if(value.id == this.questions[b].id){
            //("true")
            this.questions[b].checked = true
            //("questions.checked", this.questions[b].checked)
            //("questions", this.questions)
            break
          }
        }
          this.$emit("chooseQuestion", value);
      }else if(checked == true){
        for(let a = 0; a < this.questions.length; a++){
          if(value.id == this.questions[a].id){
            this.questions[a].mark = 0
            this.questions[a].checked = false
            break
          }
        }
            this.$emit("chooseQuestion", value);
      }

      //("unchecked", this.questions)
    },

    handleContentUpdate(id) {
      this.$emit("handleContentUpdate", id);
    },
    async handleContentDetail(id) {
      this.$emit("handleContentDetail", id);
    },
    async handleContentAdd() {
      this.$emit("handleContentAdd");
    },
    handleAdd() {
      this.$emit("handleAdd");
    },
    async dataDelete(id) {
      // Delete Data
      let result = await module.delete("api/questions/" + id);
      // If Deleted
      if (result) {
        this.getAllData();
      }
    },
    async getQuestionTypeOption() {
      let response = await module.setTreeSelect("api/question-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.question_types = response.data;
        this.question_types.unshift({
          label: "Saring Jenis Pertanyaan",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getTopicOption() {
      ApiService.get(`api/topics`)
        .then((response) => {
          this.topics = response.data.data;
          for(let a = 0; a < this.topics.length; a++){
            for(let b = 0; b < this.topics[a].children.length; b++){
              delete this.topics[a].children[b].children
            }
          }
          this.topics.unshift({
            label: "Pilih Topik",
            id: "",
            isDisabled: true,
          });
          // //("topics ===================================", this.topics);
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },

    filterByName() {
      this.getAllData();
    },
    async filterByTopic(evt) {
      //("action", evt);
      if (!evt) {
        this.filter.topic_id = await "";
        this.getAllData();
      } else {
        this.filter.topic_id = await evt;
        this.getAllData();
        //("ok", this.filter.topic_id);
      }
    },
    async filterByQuestionType(evt) {
      //("action", evt);
      if (!evt) {
        this.filter.question_type_id = await "";
        this.getAllData();
      } else {
        this.filter.question_type_id = await evt;
        this.getAllData();
        //("ok", this.filter.question_type_id);
      }
    },

    filterReset() {
      this.filter.question_type_id = ""
      this.filter.topic_id = ""
      this.filter.name = ""
      this.getAllData()    
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "7005") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted() {
    this.getAllData();
    this.checkAccess();
    this.getTopicOption();
    this.getQuestionTypeOption();

    this.$root.$on("refreshQuestion", () => {
      this.getAllData();
    });

    this.$root.$on("cancelAddMark", (id) => {
      for(let a = 0; a < this.questions.length; a++){
        if(this.questions[a].id == id){
          this.questions[a].checked = false
        }
      }
    });
  },
  computed:{
    subTotal() {
      return this.data.items_display.reduce(function (items, data) {
        // //("items", items) 
        // //("data", data.total_price) 
        let total_price = data.total_price.replace(".", "")
        let count = items + parseInt(total_price)
        let count_replace = parseInt(count).toLocaleString("id-ID")
        return items + parseInt(total_price)
      }, 0)
    },
    totalValue() {
      return this.questions.reduce(function (items, data) {
        return items + data.mark
      }, 0)
    }
  },
  watch: {
    selectedItem: function (newVal, oldVal) {
      // watch it
      //("SelectedItem changed: ", newVal, " | was: ", oldVal);
      this.setCheckedItem();
      this.setMark();
    },
  },
};
</script>

<style>
</style>