<template>
<v-app>
  <div>
    <div class="row">
      <div class="col-12">
        <b-card bg-variant="primary" text-variant="white">
          <b-card-title>{{detail.name}}</b-card-title>
          <b-card-sub-title 
            v-if="detail.courses[0].grade_name" 
            style="color: #ffffff !important"
          >Tingkat {{detail.courses[0].grade_name}}
          </b-card-sub-title>
        </b-card>
        <div class="card">
          <b-tabs content-class="" class="">
            <div class="container pt-0">
              <b-tab v-if="bankView" active>
                <template #title>
                  <span class="d-md-none d-block"
                    ><i class="fas fa-book-open"></i
                  ></span>
                  <span class="d-none d-md-block">Bahan Ajar</span>
                </template>
                <TeachingMaterial :course="detail"/>
              </b-tab>
              <b-tab>
                <template #title>
                  <span class="d-md-none d-block"
                    ><i class="fas fa-book-open"></i
                  ></span>
                  <span class="d-none d-md-block">Standar Kompetensi</span>
                </template>
                <CompetencyStandard :course="detail"/>
              </b-tab>
              <b-tab>
                <template #title>
                  <span class="d-md-none d-block"
                    ><i class="fas fa-book-open"></i
                  ></span>
                  <span class="d-none d-md-block">Kriteria Ketuntasan Minimal(KKM)</span>
                </template>
                <MinimumCompletenessCriteria :course="detail"/>
              </b-tab>
            </div>
          </b-tabs>
          <!-- <v-tabs
          v-model="currentItem"
          fixed-tabs
          slider-color="white"
        >
          <v-tab
            v-for="item in items"
            :key="item"
            :href="'#tab-' + item"
          >
            {{ item }}
          </v-tab>
        </v-tabs> -->
        <!-- <template v-if="currentItem == 'tab-Bahan Ajar'">
          kasgdhjasd
        </template>
        <template v-if="currentItem == 'tab-Standar Kompetensi'">Kom</template>
        <template v-if="currentItem == 'tab-KKM'">KKM</template> -->
        </div>
      </div>
    </div>
    <!-- <v-card>
    <v-toolbar
      color="deep-purple accent-4"
      dark
      flat
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Page title</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs
          v-model="currentItem"
          fixed-tabs
          slider-color="white"
        >
          <v-tab
            v-for="item in items"
            :key="item"
            :href="'#tab-' + item"
          >
            {{ item }}
          </v-tab>

          <v-menu
            v-if="more.length"
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                class="align-self-center mr-4"
                v-bind="attrs"
                v-on="on"
              >
                more
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>

            <v-list class="grey lighten-3">
              <v-list-item
                v-for="item in more"
                :key="item"
                @click="addItem(item)"
              >
                {{ item }}
              </v-list-item>
            </v-list>
          </v-menu>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="currentItem">
      <v-tab-item
        v-for="item in items.concat(more)"
        :key="item"
        :value="'tab-' + item"
      >
        <v-card flat>
          <v-card-text>
            jasgdjkagsdjk
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card> -->

  </div>
</v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TeachingMaterial from "@/view/components/courses/teaching-materials/TeachingMaterial";
import CompetencyStandard from "@/view/components/courses/teaching-materials/CompetencyStandard";
import MinimumCompletenessCriteria from "@/view/components/courses/teaching-materials/MinimumCompletenessCriteria";
import Material from "@/view/components/courses/materials/Material";
import Lesson from "@/view/components/courses/lessons/Lesson";
import Question from "@/view/components/courses/questions/Question";
import Quiz from "@/view/components/courses/quizzes/Quiz";
import Topic from "@/view/components/courses/topics/Topic";
import module from "@/core/modules/CrudModule.js";

export default {
  components: {
    TeachingMaterial,
    CompetencyStandard,
    MinimumCompletenessCriteria,
    Material,
    Lesson,
    Question,
    Quiz,
    Topic,
  },
  data() {
    return {
      detail: [],
      materials: [],
      // other
      dataLoaded: false,
      // testing
      currentItem: 'tab-Bahan Ajar',
      items: [
        'Bahan Ajar', 'Standar Kompetensi', 'KKM'
      ],
      more: [
        'News', 'Maps', 'Books', 'Flights', 'Apps',
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      // access
      bankView: false
    };
  },
  methods: {
    addItem (item) {
        //("item", item)
        const removed = this.items.splice(0, 1)
        this.items.push(
          ...this.more.splice(this.more.indexOf(item), 1),
        )
        this.more.push(...removed)
        this.$nextTick(() => { this.currentItem = 'tab-' + item })
      },
    async get() {
      this.detail = await module.get(
        "api/curriculums/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.$router.push("/courses");
      } else {
        this.dataLoaded = true;
        //("detail curiculum==========================", this.detail);
        //("isi prop course", this.detail);
      }
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "7001") {
          this.bankView = true
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Mata Pelajaran" }]);
    this.get();
    this.checkAccess()

    this.$root.$on("refreshCourseDetail", () => {
      // reset form on new (tambah data)
      this.get();
    });
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>