<template>
  <div class="animate__animated animate__fadeIn">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- Konten List -->
          <div v-if="content == 'list'">
            <div class="row animate__animated animate__fadeIn">
              <div class="col-12 pt-0">
                <div class="d-flex justify-content-between align-items-center mt-3 mb-6 px-8">
                  <div>
                    <div class="alert-icon d-inline">
                      <span class="svg-icon svg-icon-lg">
                        <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
                      </span>
                    </div>
                    <div class="alert-text d-inline">Daftar <b>Tugas Kuis</b></div>
                  </div>
                  <div>
                    <!-- <b-button
                    squared
                    variant="primary"
                    @click="handleAdd"
                    v-if="btnAccess"
                    >Tambah</b-button> -->
                  </div>
                </div>
                <Table 
                  purpose="master" 
                  :userData="user_data"
                  :course="course"
                  @handleContentDetail="handleContentDetail" 
                  @handleContentUpdate="handleContentUpdate"
                  @handleContentAdd="handleAdd"
                  />
              </div>
            </div>
          </div>
          <!-- Akhir::Konten List -->

          <!-- detail -->
          <div v-if="content == 'detail'">
            <Detail purpose="master" :detail="detail" @handleContent="handleContent" @handleContentUpdate="handleContentUpdate" />
          </div>
          <!--akhir::detail-->

          <!-- Konten Tambah -->
          <div v-if="content == 'tambah'">
            <h4 class="mb-5">Tambah Kuis Baru</h4>
            <Form
              :post="post"
              @handleContent="handleContent"
              @dataAdd="dataAdd"
              :route="`api/curriculums/content/${this.course.id}`"
              :course="course"
            />
          </div>
          <!-- Akhir::Konten Tambah -->

          <!-- Konten Ubah -->
          <div v-if="content == 'ubah'">
            <h4 class="mb-5">Edit Kuis</h4>
            <FormEdit
              :post="edit"
              :route="`api/quizzes/${edit.id}`"
              :questionActive="edit.question_list_display"
              @handleContent="handleContent"
              @dataAdd="dataAdd"
            />
          </div>
          <!-- Akhir::Konten Tambah -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service.js";
import Swal from "sweetalert2";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import Form from "@/view/components/courses/quizzes/Form.vue";
import FormEdit from "@/view/components/materials/quizzes/Form.vue";
import Table from "@/view/components/courses/quizzes/Table.vue";
import Detail from "@/view/components/materials/quizzes/Detail.vue";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  props:{
      course: Object
  },
  components: {
    Form,
    FormEdit,
    Table,
    Detail
  },
  data() {
    return {
      // data
      perPage: 20,
      totalRows: 1,
      currentPage: 1,
      quizzes: [],
      detail: [],
      edit: [],
      topics: [],
      durations: [],

      display: {
        question_name: "",
      },

      post: {
        title: "",
        description: "",
        question_list: "",
        duration: "",
        duration_unit_id: "",
        topic_id: "",
        total_score: "",
        purpose: "quiz-add",
        course_id: "",
      },

      error: {
        title: "",
        description: "",
        question_list: "",
        duration: "",
        duration_unit_id: "",
        topic_id: "",
      },

      user_data: {},

      // format
      content: null,
      fieldsQuestion: [
        {
          key: "number",
          label: "",
        },
        { key: "content", label: "Soal" },
      ],
      // other
      btnClearClass: "bg-white",
      // ckeditor
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "indent",
          "outdent",
          "|",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      // access
      btnAccess: false
    };
  },
  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    // handle action button
    handleContent(content) {
      this.content = content;
      this.resetPostOnNew();
      this.resetEditOnNew();
      this.resetErrorOnNew();
    },
    handleContentUpdate(id) {
      this.getTopic();
      this.getDurationUnitOption();
      this.getDataById(id);
      this.content = "ubah";
    },
    async handleContentDetail(id) {
      this.detail = await module.get("api/quizzes/" + id);
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.content = "list";
      } else {
        this.content = "detail";

        if (this.detail.questions.length != 0) {
          let a, b;
          b = this.perPage * (this.currentPage - 1) + 1;
          for (a = 0; a < this.detail.questions.length; a++) {
            this.detail.questions[a].number = b + a;
          }
        }

        if (this.detail.questions.length != 0) {
          for (let c = 0; c < this.detail.questions.length; c++) {
            if (this.detail.questions[c].answer_choices != "") {
              this.detail.questions[c].answer_choices_display = JSON.parse(
                this.detail.questions[c].answer_choices
              );
              // set jawaban multiple choices
              if(this.detail.questions[c].question_type_id == 5){
                let answer_display = JSON.parse(this.detail.questions[c].answer)
                this.detail.questions[c].answer_display = answer_display
                //("new detail", this.detail)
                // set true
                for(let d = 0; d < this.detail.questions[c].answer_choices_display.length; d++){
                  for(let e = 0; e < this.detail.questions[c].answer_display.length; e++){
                    if(this.detail.questions[c].answer_choices_display[d].value == this.detail.questions[c].answer_display[e]){
                      this.detail.questions[c].answer_choices_display[d].checked = true
                    }
                  }
                }
              }
            }
          }
        }
        //("detail", this.detail);
      }
    },
    handleAdd() {
      this.getTopic();
      this.getDurationUnitOption();
      this.content = "tambah";
    },
    resetPostOnNew() {
      let self = this;
      Object.keys(this.post).forEach(function (key, index) {
        if (key === "purpose") {
          self.purpose = "quiz-add";
        } else {
          self.post[key] = "";
        }
        // //("post", this.post)
        // //("self", self.post)
      });
    },
    resetEditOnNew() {
      let self = this;
      Object.keys(this.edit).forEach(function (key, index) {
        if (key === "formData") {
          self.edit[key] = new FormData();
        } else {
          self.edit[key] = "";
        }
        // //("edit", this.edit)
      });
    },
    resetErrorOnNew() {
      let self = this;
      Object.keys(this.error).forEach(function (key, index) {
        if (key === "formData") {
          self.error[key] = new FormData();
        } else {
          self.error[key] = "";
        }
        // //("edit", this.edit)
      });
    },

    async getDataById(id) {
      this.edit = await module.get("api/quizzes/" + id);
      // If Data Not Found
      if (this.edit == null) {
        // Redirect To List
        this.content = "list";
      } else {
        this.edit["_method"] = "put";
        this.detail = this.edit;
      }
    },

    // topic
    getTopic() {
      ApiService.setHeader();
      ApiService.get("api/topics")
        .then((response) => {
          let topic = response.data.data;
          this.topics = topic.map((elem) => ({
            id: elem.id,
            label: elem.name,
          }));
          this.topics.unshift({
            label: "Pilih Topik",
            id: "",
            isDisabled: true,
          });

          //("topics", this.topics);
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },

    async getDurationUnitOption() {
      let response = await module.setTreeSelect("api/duration-units");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.durations = response.data;
        this.durations.unshift({
          label: "Pilih Satuan Durasi",
          id: "",
          isDisabled: true,
        });
      }
    },

    async dataAdd() {
      this.resetPostOnNew();
      this.content = "list";
      this.post['_method'] = 'put'
      this.$root.$emit("refreshQuiz")
    },

    async dataUpdate(id) {
      // Make Request
      let response = await module.submit(this.edit, "api/quizzes/" + id);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$root.$emit("refreshQuiz")
        this.resetEditOnNew();
        this.handleContent("list");
      }
    },

    async dataDelete(id) {
      // Delete Data
      let result = await module.delete("api/quizzes/" + id);
      // If Deleted
      if (result) {
        this.$root.$emit("refreshQuiz")
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "7007") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted() {
    this.content = "list";
    this.user_data = getUser()
    this.checkAccess()
  },
  computed: {},
};
</script>

<style scoped>