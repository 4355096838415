<template>
  <div class="animate__animated animate__fadeIn">
    <form @submit.stop.prevent="dataAdd()">
      <b-form-group
        id="input-group-title"
        label="Judul Soal:"
        label-for="input-title"
      >
        <b-form-input
          id="input-title"
          v-model="post.title"
          placeholder="Judul Soal"
        ></b-form-input>
        <small class="text-danger">{{ error.title }}</small>
      </b-form-group>

      <b-form-group
        id="input-group-question-type-id"
        label="Jenis Pertanyaan:"
        label-for="input-question-type-id"
      >
        <treeselect
          v-model="post.question_type_id"
          :multiple="false"
          placeholder="Pilih Jenis Pertanyaan"
          :options="question_types"
          @select="setQuestionType"
        />
        <small class="text-danger">{{ error.question_type_id }}</small>
      </b-form-group>

      <!-- Single Text -->
      <template v-if="post.question_type_id == 1">
        <b-form-group
          id="input-group-content"
          label="Soal:"
          label-for="input-content"
        >
          <b-form-input
            id="input-content"
            v-model="post.content"
            placeholder="Soal"
          ></b-form-input>
          <small class="text-danger">{{ error.content }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-answer"
          label="Jawaban:"
          label-for="input-answer"
        >
          <b-form-input
            id="input-answer"
            v-model="post.answer"
            placeholder="Jawaban Soal"
          ></b-form-input>
          <small class="text-danger">{{ error.answer }}</small>
        </b-form-group>
      </template>

      <!-- Multiple Text -->
      <template v-if="post.question_type_id == 2">
        <b-form-group id="input-group-content">
          <label for="input-content">Soal:</label>
          <b-form-textarea
            id="input-content"
            v-model="post.content"
            placeholder="Soal "
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <small class="text-danger">{{ error.content }}</small>
        </b-form-group>

        <b-form-group id="input-group-answer">
          <label for="input-answer">Jawaban:</label>
          <b-form-textarea
            id="input-answer"
            v-model="post.answer"
            placeholder="Jawaban "
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <small class="text-danger">{{ error.answer }}</small>
        </b-form-group>
      </template>

      <!-- True or False -->
      <template v-if="post.question_type_id == 3">
        <b-form-group
          id="input-group-content"
          label="Soal:"
          label-for="input-content"
        >
          <b-form-input
            id="input-content"
            v-model="post.content"
            placeholder="Soal"
          ></b-form-input>
          <small class="text-danger">{{ error.content }}</small>
        </b-form-group>

        <b-form-group label="Jawaban" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-1"
            v-model="post.answer"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
          ></b-form-radio-group>
          <small class="text-danger">{{ error.answer }}</small>
        </b-form-group>
      </template>

      <!-- Single Choice -->
      <template v-if="post.question_type_id == 4">
        <b-form-group
          id="input-group-content"
          label="Soal:"
          label-for="input-content"
        >
          <b-form-input
            id="input-content"
            v-model="post.content"
            placeholder="Soal"
          ></b-form-input>
          <small class="text-danger">{{ error.content }}</small>
        </b-form-group>

        <b-form-group id="input-group-answer-choice">
          <label for="input-vendor">Opsi Jawaban</label>
          <div class="row">
            <div class="col-md-6">
              <b-form-input
                id="input-answer-choice"
                v-model="answer_collection_form.text"
                placeholder="Opsi Jawaban"
              >
              </b-form-input>
            </div>
            <div class="col-md-3">
              <b-button
                size="sm"
                class="btn-info"
                v-b-tooltip.hover
                title="Tambah Opsi Jawaban"
                type="button"
                @click="setAnswerChoice"
                v-if="answer_collection_form.text != ''"
                ><i class="fas fa-plus px-0"></i
              ></b-button>
              <b-button
                size="sm"
                class="btn-info"
                type="button"
                disabled
                v-if="answer_collection_form.text == ''"
                ><i class="fas fa-plus px-0"></i
              ></b-button>
            </div>
          </div>
          <small class="text-danger">{{ error.answer_choices }}</small>

          <div
            class="d-flex align-items-center"
            v-for="(answer, index) in answer_collection"
            :key="answer.value"
          >
            <div>
              <i
                class="fas fa-times text-danger px-0 mt-1 mr-2"
                style="cursor: pointer"
                v-b-tooltip.hover
                title="Hapus Opsi"
                @click="removeAnswerChoice(answer.value)"
              ></i>
            </div>
            <div>
              <b-form-radio
                :key="answer.value"
                :name="'some-radios-' + index"
                :value="answer.value"
                v-model="post.answer"
                >{{ answer.text }}</b-form-radio
              >
            </div>
          </div>

          <small class="text-danger">{{ error.answer }}</small>
        </b-form-group>
      </template>

      <!-- Multiple Choice -->
      <template v-if="post.question_type_id == 5">
        <b-form-group
          id="input-group-content"
          label="Soal:"
          label-for="input-content"
        >
          <b-form-input
            id="input-content"
            v-model="post.content"
            placeholder="Soal"
          ></b-form-input>
          <small class="text-danger">{{ error.content }}</small>
        </b-form-group>

        <b-form-group id="input-group-answer-choice">
          <label for="input-vendor">Opsi Jawaban</label>
          <div class="row">
            <div class="col-md-6">
              <b-form-input
                id="input-answer-choice"
                v-model="answer_collection_form.text"
                placeholder="Jawaban"
              >
              </b-form-input>
            </div>
            <div class="col-md-3">
              <b-button
                size="sm"
                class="btn-info"
                v-b-tooltip.hover
                title="Tambah Jawaban"
                type="button"
                @click="setAnswerChoice"
                v-if="answer_collection_form.text != ''"
                ><i class="fas fa-plus px-0"></i
              ></b-button>
              <b-button
                size="sm"
                class="btn-info"
                disabled
                v-if="answer_collection_form.text == ''"
                ><i class="fas fa-plus px-0"></i
              ></b-button>
            </div>
          </div>
          <small class="text-danger">{{ error.answer_choices }}</small>
          <table>
            <tr v-for="answer in answer_collection" :key="answer.value">
              <th class="pr-4">
                <i
                  class="fas fa-times text-danger px-0"
                  style="cursor: pointer"
                  v-b-tooltip.hover
                  title="Hapus Jawaban"
                  @click="removeAnswerChoice(answer.value)"
                ></i>
              </th>
              <!-- <th>{{answer}}</th> -->
              <td class="pr-0">
                <b-form-checkbox
                  :checked="answer.checked"
                  :id="`checkbox-pick-item-${answer.value}`"
                  :name="`checkbox-pick-item-${answer.value}`"
                  @change="chooseAnswerMultiple(answer.value)"
                >
                </b-form-checkbox>
              </td>
              <td class="pr-0">
                <span
                  style="cursor: pointer"
                  @click="chooseAnswerMultiple(answer.value)"
                  >{{ answer.text }}</span
                >
              </td>
            </tr>
          </table>
        </b-form-group>

        <!-- <b-form-group label="Jawaban" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-1"
            v-model="post.answer"
            :options="answer_collection"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
            stacked
          ></b-form-radio-group>
        </b-form-group> -->
      </template>

      <!-- Completion -->
      <template v-if="post.question_type_id == 6">
        <b-form-group id="input-group-content">
          <label for="input-content">Soal:</label>
          <b-form-textarea
            id="input-content"
            v-model="post.content"
            placeholder="Soal "
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <small class="text-danger">{{ error.content }}</small>
        </b-form-group>
        <!-- <Chips v-model="completion_collection_form.answer" separator="," /> -->
        <b-form-group id="input-group-answer-choice">
          <label for="input-vendor">Opsi Jawaban</label>
          <div class="row">
            <div class="col-md-6">
              <b-form-input
                id="input-answer-choice"
                v-model="answer_collection_completion_form.text"
                placeholder="Opsi Jawaban"
              >
              </b-form-input>
            </div>
            <div class="col-md-3">
              <b-button
                size="sm"
                class="btn-info"
                v-b-tooltip.hover
                title="Tambah Opsi Jawaban"
                type="button"
                @click="setAnswerCompletionChoice"
                v-if="answer_collection_completion_form.text != ''"
                ><i class="fas fa-plus px-0"></i
              ></b-button>
              <b-button
                size="sm"
                class="btn-info"
                disabled
                v-if="answer_collection_completion_form.text == ''"
                ><i class="fas fa-plus px-0"></i
              ></b-button>
            </div>
          </div>
          <small class="text-danger">{{ error.answer_choices }}</small>
          <table>
            <draggable v-model="answer_collection" tag="tbody">
              <tr
                v-for="(answer, index) in answer_collection"
                :key="answer.value"
              >
                <th class="pr-4">
                  <i
                    class="fas fa-times text-danger px-0"
                    style="cursor: pointer"
                    v-b-tooltip.hover
                    title="Hapus Opsi"
                    @click="removeAnswerChoice(answer.value)"
                  ></i>
                </th>
                <!-- <th>{{answer}}</th> -->
                <th>
                  <span>{{ index + 1 + ". " }}</span>
                </th>
                <th>
                  <span
                    style="cursor: pointer"
                    @click="chooseAnswerMultiple(answer.value)"
                    >{{ " " + answer.text }}</span
                  >
                </th>
              </tr>
            </draggable>
          </table>
        </b-form-group>
      </template>

      <b-form-group
        id="input-group-topic-id"
        label="Topik:"
        label-for="input-topic-id"
      >
        <treeselect
          v-model="post.topic_id"
          :multiple="false"
          placeholder="Pilih Topik"
          :options="topics"
        />
        <small class="text-danger">{{ error.topic_id }}</small>
      </b-form-group>

      <b-form-group id="input-group-explanation">
        <label for="input-explanation"
          >Penjelasan: <em class="text-muted">opsional</em></label
        >
        <b-form-input
          id="input-explanation"
          v-model="post.explanation"
          placeholder="Penjelasan"
        ></b-form-input>
        <small class="text-danger">{{ error.explanation }}</small>
      </b-form-group>

      <b-form-group id="input-group-hint">
        <label for="input-hint"
          >Petunjuk: <em class="text-muted">opsional</em></label
        >
        <b-form-input
          id="input-hint"
          v-model="post.hint"
          placeholder="Petunjuk"
        ></b-form-input>
        <small class="text-danger">{{ error.hint }}</small>
      </b-form-group>

      <b-form-group id="input-group-tag">
        <label for="input-tag">Tag: <em class="text-muted">opsional</em></label>
        <b-form-input
          id="input-tag"
          v-model="post.tag"
          placeholder="Tag"
        ></b-form-input>
        <small class="text-danger">{{ error.tag }}</small>
      </b-form-group>

      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="handleContent"
      >
        Batal
      </b-button>
    </form>
  </div>
</template>

<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Chips from "primevue/chips";
import draggable from "vuedraggable";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import ApiService from "@/core/services/api.service.js";

export default {
  props: {
    post: Object,
    course: Object
  },
  components: {
    Chips,
    draggable,
  },
  data() {
    return {
      topics: [],
      question_types: [],
      error: {
        title: "",
        content: "",
        question_type_id: "",
        answer: "",
        answer_choices: "",
        explanation: "",
        hint: "",
        tag: "",
        topic_id: "",
      },
      options: [
        { text: "Benar", value: "1" },
        { text: "Salah", value: "0" },
      ],
      answer_collection_form: {
        text: "",
        value: "",
        checked: false,
      },
      answer_collection: [],
      answer_value: 0,
      answer_multiple: [],
      // completion
      completion_content: "",
      completion_collection: [],
      answer_collection_completion_form: {
        text: "",
        value: "",
      },
      completion_testing: "",
      // other
      user_data: []
    };
  },
  methods: {
    handleContent() {
      this.$emit("handleContent", "list");
      this.resetAnswerChoice();
    },
    async getQuestionTypeOption() {
      let response = await module.setTreeSelect("api/question-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.question_types = response.data;
        this.question_types.unshift({
          label: "Pilih Jenis Pertanyaan",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getTopicOption() {
      ApiService.get(`api/topics/my-topics/${getUser().id}`)
        .then((response) => {
          this.topics = response.data.data;
          for(let a = 0; a < this.topics.length; a++){
            for(let b = 0; b < this.topics[a].children.length; b++){
              delete this.topics[a].children[b].children
            }
          }
          this.topics.unshift({
            label: "Pilih Topik",
            id: "",
            isDisabled: true,
          });
          //("topics ===================================", this.topics);
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    setQuestionType(evt) {
      //("evt", evt);
      this.answer_collection = [];
      this.post.answer_choices = "";
      this.answer_collection_form.text = "";
      this.answer_collection_form.value = "";
      this.answer_collection_form.checked = false;

      if (evt.id == 6) {
        this.completion_content =
          "kalimat(1)......kalimat)(2)......kalimat(3)......kalimat";
        this.post.content =
          "kalimat(1)......kalimat)(2)......kalimat(3)......kalimat";
      }
    },
    setAnswerChoice() {
      this.error.answer_choices = "";
      this.answer_value = this.answer_value + 1;
      this.answer_collection_form.value = this.answer_value;
      let clone = { ...this.answer_collection_form };
      this.answer_collection.push(clone);
      //("answer choice", this.answer_collection);
      this.answer_collection_form.text = "";
    },
    setAnswerCompletionChoice() {
      this.error.answer_choices = "";
      this.answer_value = this.answer_value + 1;
      this.answer_collection_completion_form.value = this.answer_value;
      let clone = { ...this.answer_collection_completion_form };
      this.answer_collection.push(clone);
      //("answer choice", this.answer_collection);
      this.answer_collection_completion_form.text = "";
    },
    removeAnswerChoice(id) {
      for (let a = 0; a < this.answer_collection.length; a++) {
        if (this.answer_collection[a].value == id) {
          this.answer_collection.splice(a, 1);
          //("remove", this.answer_collection);
        }
        // if (this.post.question_type_id == 4) {
        //   if(this.answer_collection[a].value == this.post.answer){
        //     this.post.answer = "";
        //     //("post", this.post);
        //   }
        // }
      }

      // if (this.post.question_type_id == 4) {
      //   this.post.answer = "";
      //   //("post", this.post);
      // }
    },
    resetAnswerChoice() {
      this.answer_collection = [];
      this.answer_collection_form.value = "";
      this.answer_collection_form.text = "";
    },
    chooseAnswer(id) {
      for (let a = 0; a < this.answer_collection.length; a++) {
        if (this.answer_collection[a].value == id) {
          if (this.answer_collection[a].checked == true) {
            this.answer_collection[a].checked = false;
            this.post.answer = "";
          } else {
            this.answer_collection[a].checked = true;
            this.post.answer = this.answer_collection[a].value.toString();
          }
        } else {
          this.answer_collection[a].checked = false;
          this.post.answer = "";
        }
      }
      //("checked", this.answer_collection);
    },
    chooseAnswerMultiple(id) {
      for (let a = 0; a < this.answer_collection.length; a++) {
        if (
          this.answer_collection[a].value == id &&
          this.answer_collection[a].checked == false
        ) {
          this.answer_collection[a].checked = true;
        } else if (
          this.answer_collection[a].value == id &&
          this.answer_collection[a].checked == true
        ) {
          this.answer_collection[a].checked = false;
        }
      }
      //("checked", this.answer_collection);
    },
    async dataAdd() {
      this.resetErrorOnNew();
      let valid = await this.validation();
      //("validation", valid);

      if (valid == "success") {
        if (this.post.question_type_id == 3) {
          this.post.answer_choices = JSON.stringify(this.options);
        }
        if (this.post.question_type_id == 4) {
          // for (let a = 0; a < this.answer_collection.length; a++) {
          //   if (this.answer_collection[a].checked == true) {
          //     this.answer_collection[a].checked = false;
          //     this.post.answer = this.answer_collection[a].value.toString();
          //   }
          // }
          this.post.answer = this.post.answer.toString()
          this.post.answer_choices = JSON.stringify(this.answer_collection);
        }
        if (this.post.question_type_id == 5) {
          for (let a = 0; a < this.answer_collection.length; a++) {
            if (this.answer_collection[a].checked == true) {
              this.answer_collection[a].checked = false;
              this.answer_multiple.push(this.answer_collection[a].value);
            }
          }
          //("answer multiple", this.answer_multiple);
          this.post.answer = JSON.stringify(this.answer_multiple);
          this.post.answer_choices = JSON.stringify(this.answer_collection);
          // //("post", this.answer_multiple)
        }
        if (this.post.question_type_id == 6) {
          for (let a = 0; a < this.answer_collection.length; a++) {
            this.answer_collection[a].value = a + 1;
          }
          // this.post.content = this.completion_content
          // let choices = this.post.content.split("......")
          // this.post.answer_choices = JSON.stringify(choices)
          // this.post.answer_choices = choices

          //("content_completion", this.post.content);
          this.post.answer_choices = JSON.stringify(this.answer_collection);
        }
        this.post['_method'] = 'put'
        //("post", this.post);

        // Make Request
        let response = await module.submit(this.post, `api/curriculums/content/${this.course.id}`);
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          // Success
          Swal.fire(
            response.success.title,
            response.success.message,
            "success"
          );
          this.$root.$emit("refreshQuestion");
          this.$emit("dataAdd");
        }
      }
    },
    validation() {
      let response = "success";
      //  Single Text && Multiple Text
      if (this.post.question_type_id == 1 || this.post.question_type_id == 2) {
        if (this.post.answer == "" && this.post.content != "") {
          this.validationMessage();
          this.error.answer = "Kolom jawaban tidak boleh kosong";
          response = null;
        }
      }
      // True Or False
      if (this.post.question_type_id == 3) {
        if (this.post.answer == "" && this.post.content != "") {
          this.validationMessage();
          this.error.answer = "Jawaban belum dipilih";
          response = null;
        }
      }
      // Single Choice
      if (this.post.question_type_id == 4) {
        if (this.post.answer == "" && this.post.content != "") {
          this.validationMessage();
          this.error.answer = "Jawaban belum dipilih";
          response = null;
        }
      }
      // Multiple Choice
      if (this.post.question_type_id == 5) {
        if (this.answer_collection.length == 0 && this.post.content != "") {
          this.validationMessage();
          this.error.answer_choices = "Opsi jawaban tidak boleh kosong";
          response = null;
        }

        if (this.answer_collection.length != 0) {
          for (let a = 0; a < this.answer_collection.length; a++) {
            if (this.answer_collection[a].checked == true) {
              break;
            } else if (a == this.answer_collection.length - 1) {
              this.error.answer = "Jawaban belum dipilih";
              response = null;
            }
          }
        }
      }
      return response;
    },
    validationMessage() {
      Swal.fire({
        title: "Gagal",
        text: "Harap periksa kembali form",
        icon: "error",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    resetErrorOnNew() {
      let self = this;
      Object.keys(this.error).forEach(function (key, index) {
        self.error[key] = "";

        // //("edit", this.edit)
      });
    },
  },
  mounted() {
    this.getQuestionTypeOption();
    this.getTopicOption();
  },
};
</script>

<style>
</style>