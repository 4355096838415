<template>
  <div class="mt-3">
    <v-tabs
      v-model="currentItem"
      fixed-tabs
      slider-color="#244CA3"
      background-color="#d6e2f8"
    >
      <v-tab
        v-for="item in items"
        :key="item"
        :href="'#tab-' + item"
        class="text-primary bg-hover-tab"
      >
        {{ item }}
      </v-tab>
    </v-tabs>

    <div class="my-3"></div>

    <div :class="currentItem == 'tab-Pelajaran' ? 'd-block' : 'd-none'">
      <Lesson :course="course" />
    </div>
    <div :class="currentItem == 'tab-Bank Soal' ? 'd-block' : 'd-none'">
      <Question :course="course" />
    </div>
    <div :class="currentItem == 'tab-Kuis' ? 'd-block' : 'd-none'">
      <Quiz :course="course" />
    </div>
    <div :class="currentItem == 'tab-Materi Pendukung' ? 'd-block' : 'd-none'">
      <Material :course="course" />
    </div>
    <div :class="currentItem == 'tab-Topik' ? 'd-block' : 'd-none'">
      <Topic :course="course" />
    </div>
    <!-- <b-tabs content-class="" class="">
      <div class="container pt-0">
        <b-tab active>
          <template #title>
            <span class="d-md-none d-block"
              ><i class="fas fa-book-open"></i
            ></span>
            <span class="d-none d-md-block">Pelajaran</span>
          </template>
          <Lesson :course="course" />
        </b-tab>
        <b-tab>
          <template #title>
            <span class="d-md-none d-block"
              ><i class="fas fa-file-invoice"></i
            ></span>
            <span class="d-none d-md-block">Bank Soal</span>
          </template>
          <Question :course="course" />
        </b-tab>
        <b-tab>
          <template #title>
            <span class="d-md-none d-block"
              ><i class="fas fa-question-circle"></i
            ></span>
            <span class="d-none d-md-block">Kuis</span>
          </template>
          <Quiz :course="course" />
        </b-tab>
        <b-tab>
          <template #title>
            <span class="d-md-none d-block"
              ><i class="fas fa-file-alt"></i
            ></span>
            <span class="d-none d-md-block">Materi Pendukung</span>
          </template>
          <Material :course="course" />
        </b-tab>
        <b-tab>
          <template #title>
            <span class="d-md-none d-block"
              ><i class="fab fa-stack-exchange"></i
            ></span>
            <span class="d-none d-md-block">Topik</span>
          </template>
          <Topic :course="course" />
        </b-tab>
      </div>
    </b-tabs> -->
  </div>
</template>

<script>
import Material from "@/view/components/courses/materials/Material";
import Lesson from "@/view/components/courses/lessons/Lesson";
import Question from "@/view/components/courses/questions/Question";
import Quiz from "@/view/components/courses/quizzes/Quiz";
import Topic from "@/view/components/courses/topics/Topic";
import module from "@/core/modules/CrudModule.js";

export default {
  components: {
    Material,
    Lesson,
    Question,
    Quiz,
    Topic,
  },
  props: {
    course: Object,
  },
  data() {
    return {
      detail: [],
      materials: [],
      // other
      dataLoaded: false,
      // testing
      // currentItem: "tab-Pelajaran",
      // items: ["Pelajaran", "Bank Soal", "Kuis", "Materi Pendukung", "Topik"],
      items: [],
      currentItem: "",
      more: ["News", "Maps", "Books", "Flights", "Apps"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      // access
      lessonView: false,
      questionView: false,
      quizView: false,
      materialView: false,
      topicView: false,
    };
  },
  methods: {
    addItem(item) {
      //("item", item);
      const removed = this.items.splice(0, 1);
      this.items.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item;
      });
    },
    async get() {
      this.detail = await module.get(
        "api/curriculums/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.$router.push("/courses");
      } else {
        this.dataLoaded = true;
        //("detail curiculum==========================", this.detail);
        //("isi prop course", this.detail);
      }
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      // items: ["Pelajaran", "Bank Soal", "Kuis", "Materi Pendukung", "Topik"],
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "7002") {
          this.items.push("Pelajaran");
          this.currentItem = "tab-Pelajaran";
          this.lessonView = true;
        }
        if (access_right[a] == "7004") {
          this.items.push("Bank Soal");
          this.currentItem = "tab-Bank Soal";
          this.questionView = true;
        }
        if (access_right[a] == "7006") {
          this.items.push("Kuis");
          this.currentItem = "tab-Kuis";
          this.quizView = true;
        }
        if (access_right[a] == "7008") {
          this.items.push("Materi Pendukung");
          this.currentItem = "tab-Materi Pendukung";
          this.materialView = true;
        }
        if (access_right[a] == "7010") {
          this.items.push("Topik");
          this.currentItem = "tab-Topik";
          this.topicView = true;
        }
        //("items", this.items);
      }
    },
  },
  mounted() {
    this.get();
    this.checkAccess();

    this.$root.$on("refreshCourseDetail", () => {
      // reset form on new (tambah data)
      this.get();
    });
  },
};
</script>

<style>
.bg-hover-tab:hover {
  background-color: #adc5f1;
  font-weight: bolder;
}

.v-tab--active {
  background-color: #adc5f1;
  font-weight: bolder !important;
}
</style>