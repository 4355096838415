<template>
  <div class="animate__animated animate__fadeIn">
    <form @submit.stop.prevent="dataAdd()">
      <b-form-group
        id="input-group-title"
        label="Judul Kuis:"
        label-for="input-title"
      >
        <b-form-input
          id="input-title"
          v-model="post.title"
          placeholder="Judul Kuis"
        ></b-form-input>
        <small class="text-danger">{{ error.title }}</small>
      </b-form-group>

      <b-form-group id="input-group-trigger-modal-question">
        <label for="input-trigger-modal-question">Soal:</label>
        <b-input-group>
          <b-form-input
            id="input-trigger-modal-question"
            v-model="display.question_name"
            placeholder="Pilih Soal"
            readonly
            @click="$bvModal.show('modal-question')"
          >
          </b-form-input>
          <template #append>
            <b-input-group-text
              squared
              :class="btnClearClass"
              @click="resetQuestion"
              style="border-left: none"
              @mouseover="btnClearClass = 'bg-light-danger'"
              @mouseout="btnClearClass = 'bg-white'"
            >
              <i class="fas fa-sm fa-times text-danger"></i>
            </b-input-group-text>
          </template>
        </b-input-group>
        <small class="text-danger">{{ error.question_list }}</small>
      </b-form-group>

<div class="d-flex justify-content-end" v-if="question_collection.length > 0">
  <span>Total Nilai: {{totalValue}}</span>
</div>
      <div class="bg-light" v-if="question_collection.length > 0">
        <table class="table">
          <tr>
            <th></th>
            <th>No</th>
            <th>Soal</th>
            <th>Jenis Soal</th>
            <th>Nilai Soal</th>
            <th>Aksi</th>
          </tr>
          <draggable v-model="question_collection" tag="tbody" @update="update">
            <tr
              v-for="(question, index) in question_collection"
              :key="question.id"
              style="cursor: pointer"
            >
              <td><i class="fas fa-arrows-alt px-0"></i></td>
              <td>{{ index + 1 }}</td>
              <td><div v-html="question.content"></div></td>
              <td>{{ question.question_type_name }}</td>
              <td>{{ question.mark }}</td>
              <td>
                <!-- <b-button
                  size="sm"
                  class="btn-info"
                  v-b-tooltip.hover.left
                  :title="'Edit Nilai ' + question.id"
                  type="button"
                  @click="handleQuestionCollectionUpdate(index)"
                  ><i class="fas fa-edit px-0"></i
                ></b-button> -->
                <b-button
                  size="sm"
                  class="btn-danger ml-2"
                  v-b-tooltip.hover.left
                  title="Hapus Soal"
                  type="button"
                  @click="removeQuestion(question.id)"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </td>
            </tr>
          </draggable>
        </table>
      </div>

      <b-form-group
        id="input-group-duration"
        label="Durasi:"
        label-for="input-duration"
      >
        <b-form-input
          id="input-duration"
          v-model="post.duration"
          placeholder="Durasi"
        ></b-form-input>
        <small class="text-danger">{{ error.duration }}</small>
      </b-form-group>

      <b-form-group
        id="input-group-duration-unit"
        label="Satuan Durasi:"
        label-for="input-duration-unit"
      >
        <treeselect
          v-model="post.duration_unit_id"
          :multiple="false"
          placeholder="Pilih Satuan Durasi"
          :options="durations"
        />
        <small class="text-danger">{{ error.duration_unit_id }}</small>
      </b-form-group>

      <b-form-group
        id="input-group-topics"
        label="Topik:"
        label-for="input-topics"
      >
        <treeselect
          v-model="post.topic_id"
          :multiple="false"
          placeholder="Pilih Topik"
          :options="topics"
        />
        <small class="text-danger">{{ error.topic_id }}</small>
      </b-form-group>
      <b-form-group id="input-group-description">
        <label for="input-description"
          >Deskripsi: <em class="text-muted">opsional</em></label
        >
        <b-form-textarea
          id="input-description"
          v-model="post.description"
          placeholder="Deskripsi Format"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="handleContent"
      >
        Batal
      </b-button>
    </form>

<!-- Input Nilai -->
    <b-modal
      ref="my-modal"
      hide-footer
      title="Nilai Soal"
      @hide="hideModalCancel"
      hide-header
    >
      <b-form @submit.stop.prevent="questionFormOnsubmit">
        <!-- <div class="d-block text-center"> -->
        <b-form-group
          id="input-group-mark"
          label="Nilai:"
          label-for="input-mark"
        >
          <b-form-input
            id="input-mark"
            v-model="question_collection_form.mark"
            placeholder="Nilai Soal"
            autofocus
          ></b-form-input>
          <small class="text-danger">{{
            question_collection_error.mark
          }}</small>
        </b-form-group>
        <!-- </div> -->
        <!-- Submit & Cancel button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="hideModalCancel"
        >
          Cancel
        </b-button>
      </b-form>
    </b-modal>

    <!-- Edit Nilai -->
    <b-modal
      ref="my-modal-update"
      hide-footer
      title="Nilai Soal"
      @hide="hideModal"
      hide-header
    >
      <b-form @submit.stop.prevent="questionFormOnUpdate(question_collection_form.id)">
        <!-- <div class="d-block text-center"> -->
        <!-- <b-form-group
          id="input-group-title"
          label="Judul:"
          label-for="input-title"
        >
          <b-form-input
            id="input-title"
            v-model="question_collection_form.title"
            placeholder="Judul Soal"
            disabled
          ></b-form-input>
          <small class="text-danger">{{
            question_collection_error.title
          }}</small>
        </b-form-group>
        <b-form-group
          id="input-group-content"
          label="Soal:"
          label-for="input-content"
        >
          <b-form-input
            id="input-content"
            v-model="question_collection_form.content"
            placeholder="Soal"
            disabled
          ></b-form-input>
          <small class="text-danger">{{
            question_collection_error.content
          }}</small>
        </b-form-group>
        <b-form-group
          id="input-group-question-type"
          label="Jenis Soal:"
          label-for="input-question-type"
        >
          <b-form-input
            id="input-question-type"
            v-model="question_collection_form.question_type_name"
            placeholder="Jenis Soal"
            disabled
          ></b-form-input>
          <small class="text-danger">{{
            question_collection_error.question_type_name
          }}</small>
        </b-form-group> -->
        <b-form-group
          :id="'input-group-mark' + question_collection_form.title"
          label="Nilai:"
          :label-for="'input-mark' + question_collection_form.title"
        >
          <b-form-input
            :id="'input-mark' + question_collection_form.title"
            v-model="question_collection_form.mark"
            placeholder="Nilai Soal"
            autofocus
          ></b-form-input>
          <small class="text-danger">{{
            question_collection_error.mark
          }}</small>
        </b-form-group>
        <!-- </div> -->
        <!-- Submit & Cancel button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="hideModalUpdate"
        >
          Cancel
        </b-button>
      </b-form>
    </b-modal>

<!-- Input Question -->
    <ModalQuestion
      @chooseQuestion="setQuestion"
      :selectedItem="question_collection"
      :userData="user_data"
      :course="course"
    />
  </div>
</template>

<script>
import ModalQuestion from "@/view/components/general/ModalQuestionCurriculum.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import draggable from "vuedraggable";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import ApiService from "@/core/services/api.service.js";

export default {
  props: {
    post: Object,
    route: String,
    questionActive: Array,
    course: Object
  },
  components: {
    ModalQuestion,
    draggable,
  },
  data() {
    return {
      disabled:true,
      display: {
        question_name: "",
      },
      error: {
        title: "",
        content: "",
        question_list: "",
        mark: "",
        explanation: "",
        hint: "",
        tag: "",
        topic_id: "",
      },
      topics: [],
      durations: [],
      btnClearClass: "bg-white",
      question_id: [],
      question_collection: [],
      question_collection_form: {
        id: "",
        content: "",
        title: "",
        mark: "",
        question_type_name: "",
      },
      question_collection_error: {
        id: "",
        content: "",
        title: "",
        mark: "",
        question_type_name: "",
      },
      question_collection_submit: false,
      total_score: "",
      // other
      user_data: []
    };
  },
  methods: {
    choose(choose) {
      //("choose", choose);
    },
    clone(clone) {
      //("clone", clone);
    },
    start(start) {
      //("start", start);
    },
    unchoose(unchoose) {
      //("unchoose", unchoose);
    },
    update(update) {
      //("update", update);
    },
    sort(sort) {
      //("sort", sort);
    },
    end(end) {
      //("end", end);
    },
    setDisable() {
      this.disabled = false
    },
    handleContent() {
      this.$emit("handleContent", "list");
      this.question_id = [];
      this.question_collection = [];
      this.question_name = "";
    },
    async getDurationUnitOption() {
      let response = await module.setTreeSelect("api/duration-units");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.durations = response.data;
        this.durations.unshift({
          label: "Pilih Satuan Durasi",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getTopicOption() {
      ApiService.get(`api/topics/my-topics/${getUser().id}`)
        .then((response) => {
          this.topics = response.data.data;
          for(let a = 0; a < this.topics.length; a++){
            for(let b = 0; b < this.topics[a].children.length; b++){
              delete this.topics[a].children[b].children
            }
          }
          this.topics.unshift({
            label: "Pilih Topik",
            id: "",
            isDisabled: true,
          });
          //("topics ===================================", this.topics);
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },

    async hideModalCancel() {
      if (this.question_collection_submit == false) {
        //("tidak tekan submit");
        this.$root.$emit("cancelAddMark", this.question_collection_form.id);
        // this.medicine_id.pop();
      }
      await this.$refs["my-modal"].hide();
      this.setQuestionSubmit();
    },

    setQuestionSubmit() {
      this.question_collection_submit = false;
    },
    async setQuestion(value) {
      if (this.question_collection.length > 0) {
        let a;
        for (a = 0; a < this.question_collection.length; a++) {
          if (this.question_collection[a].id == value.id) {
            this.question_collection.splice(a, 1);
            if (this.question_collection.length == 0) {
              this.display.question_name = "";
              break;
            } else if (this.question_collection.length > 0) {
              this.displayQuestionName(this.question_collection);
              break;
            }
            break;
          } else if (a == this.question_collection.length - 1) {
            // this.$bvModal.hide("modal-question");
            //("value", value);
            this.question_collection_form.id = value.id;
            this.question_collection_form.content = value.content;
            this.question_collection_form.title = value.title;
            this.question_collection_form.question_type_name = value.question_type_name;
            this.question_collection_form.mark = "";
            this.$refs["my-modal"].show();
          }
        }
      } else {
        // this.$bvModal.hide("modal-question");
        //("value", value);
        this.question_collection_form.id = value.id;
        this.question_collection_form.content = value.content;
        this.question_collection_form.title = value.title;
        this.question_collection_form.question_type_name =
          value.question_type_name;
        this.question_collection_form.mark = "";
        this.$refs["my-modal"].show();
      }
    },

    displayQuestionName(participant) {
      if (participant.length == 0) {
        this.display.question_name = "";
      } else {
        let str = "";
        participant.forEach(function (value, index) {
          str += value.title;
          if (index != participant.length - 1) {
            if (participant.length > 1) str += ", ";
          }
        });
        this.display.question_name = str;
      }
    },
    resetQuestion() {
      this.question_collection.splice(0, this.question_collection.length);
      //("reset", this.question_collection);
      this.display.question_name = "";
    },
    removeQuestion($id) {
      //("remove question", $id);
      let a;
      for (a = 0; a < this.question_collection.length; a++) {
        if (this.question_collection[a].id == $id) {
          this.question_collection.splice(a, 1);
          //("question_collection", this.question_collection);
        }
      }
      this.displayQuestionName(this.question_collection);
    },
    questionFormOnsubmit() {
      if (this.question_collection_form.mark != "") {
        this.question_collection_submit = true;
        let clone = { ...this.question_collection_form };
        this.question_collection.push(clone);

        //("hasil", this.question_collection);
        this.displayQuestionName(this.question_collection);
        this.hideModal();
        this.resetErrorQuestionOnNew();
      } else {
        Swal.fire({
          title: "Gagal",
          text: "Anda belum mengisi nilai",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.question_collection_error.mark = "Kolom nilai tidak boleh kosong";
      }
    },
    async dataAdd() {
      if (this.question_collection.length != 0) {
        this.post.total_score = this.totalValue
        this.error.question_list = "";
        this.post.question_list = JSON.stringify(this.question_collection);
        this.post['_method'] = 'put'
        this.post.course_id = this.course.course_id
        //("post", this.post);
        // Make Request
        let response = await module.submit(this.post, this.route);
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
          JSON.parse(this.question_id);
        } else {
          // Success
          Swal.fire(
            response.success.title,
            response.success.message,
            "success"
          );
          this.$emit("dataAdd");
        }
      } else {
        Swal.fire({
          title: "Gagal",
          text: "Anda belum memilih soal",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.error.question_list = "Kolom ini tidak boleh kosong";
      }
    },
    resetQuestionCollectionFormOnNew() {
      let self = this;
      Object.keys(this.question_collection_form).forEach(function (
        key,
        index
      ) {
        self.question_collection_form[key] = "";
      });
    },
    setQuestionActive() {
      this.question_collection = this.questionActive;
      this.displayQuestionName(this.questionActive);
      //("question active", this.question_collection);
    },
    handleQuestionCollectionUpdate(index){
      //("index", index)
      //("this.question", this.question_collection)
      //("this.question-" + index, this.question_collection[index])
          this.question_collection_form.id = this.question_collection[index].id
          this.question_collection_form.title = this.question_collection[index].title
          this.question_collection_form.content = this.question_collection[index].content
          this.question_collection_form.question_type_name = this.question_collection[index].question_type_name
          this.question_collection_form.mark = this.question_collection[index].mark
          this.showModalUpdate()
    },
    resetErrorQuestionOnNew() {
      let self = this;
      Object.keys(this.question_collection_error).forEach(function (
        key,
        index
      ) {
        self.question_collection_error[key] = "";
      });
    },
    questionFormOnUpdate(id) {
      //("id for update", id)
      if (this.question_collection_form.mark != "") {
        // let clone = { ...this.question_collection_form };
      let objIndex = this.question_collection.findIndex((obj => obj.id == id));
      this.question_collection[objIndex] = this.question_collection_form
      //("question collection form", this.question_collection_form)
      //("new question collection", this.question_collection)
      //("question collection - " +objIndex, this.question_collection[objIndex])

        //("hasil", this.question_collection);
        this.displayQuestionName(this.question_collection);
        this.hideModalUpdate();
        this.resetErrorQuestionOnNew();
      } else {
        Swal.fire({
          title: "Gagal",
          text: "Anda belum mengisi nilai",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.question_collection_error.mark = "Kolom nilai tidak boleh kosong";
        this.question_collection = this.question_collection
      }
    },
    showModalUpdate() {
      this.$refs["my-modal-update"].show();
    },
    hideModalUpdate() {
      this.$refs["my-modal-update"].hide();
    },
  },
  mounted() {
    this.getDurationUnitOption();
    this.getTopicOption();
    this.user_data = getUser()
  },
  computed:{
    totalValue() {
      return this.question_collection.reduce(function (items, data) {
        return items + parseInt(data.mark)
      }, 0)
    }
  },
  watch: {
    questionActive: function (newVal) {
      this.setQuestionActive();
    },
  },
};
</script>

<style>
</style>