<template>
  <div class="animate__animated animate__fadeIn">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- Konten List -->
          <div v-if="content == 'list'">
            <div class="row animate__animated animate__fadeIn">
              <div class="col-12 pt-0">

                <div class="d-flex justify-content-between align-items-center  mt-3 mb-6 px-8">
                  <div>
                    <div class="alert-icon d-inline">
                      <span class="svg-icon svg-icon-lg">
                        <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
                      </span>
                    </div>
                    <div class="alert-text d-inline">Daftar <b>Materi Pendukung</b></div>
                  </div>
                  <div>
                    <!-- <b-button
                    squared
                    variant="primary"
                    @click="handleAdd"
                    v-if="btnAccess"
                    >Tambah</b-button> -->
                  </div>
                </div>

                <Table
                  purpose="master"
                  @handleContentDetail="handleContentDetail"
                  @handleContentUpdate="handleContentUpdate"
                  @handleContentAdd="handleAdd"
                  :userData="user_data"
                  :course="course"
                />
              </div>
            </div>
          </div>
          <!-- Akhir::Konten List -->

          <!-- detail -->
          <div v-if="content == 'detail'">
            <div class="row animate__animated animate__fadeIn">
              <div class="col-12">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h5
                    class="
                      font-weight-bolder
                      text-dark
                      font-size-h6 font-size-h3-lg
                    "
                  >
                    Detail Materi
                  </h5>

                  <table class="table mt-8">
                    <tr>
                      <td class="pr-1">
                        <span class="font-weight-bold">Materi</span>
                      </td>
                      <td class="pl-0 pr-0" width="10">
                        <span class="font-weight-bold">:</span>
                      </td>
                      <td class="pl-2">
                        <span>{{ detail.title }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <span class="font-weight-bold">Topik</span>
                      </td>
                      <td class="pl-0 pr-0" width="10">
                        <span class="font-weight-bold">:</span>
                      </td>
                      <td class="pl-2">
                        <span>{{ detail.topic_name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <span class="font-weight-bold">Deskripsi/Kontent</span>
                      </td>
                      <td class="pl-0 pr-0" width="10">
                        <span class="font-weight-bold">:</span>
                      </td>
                      <td class="pl-2">
                        <span v-html="detail.description"></span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <span class="font-weight-bold">Lampiran</span>
                      </td>
                      <td class="pl-0 pr-0" width="10">
                        <span class="font-weight-bold">:</span>
                      </td>
                      <td class="pl-2">
                        <ul
                          v-for="(attach, index) in attachment_list_display"
                          :key="attach.id"
                        >
                          <li>
                            <a
                              download="file"
                              target="_blank"
                              :href="attachment_list_display[index]"
                              v-if="isPdf(file_name[index]) == false"
                              >
                              {{ file_name[index] }}
                              </a>
                            <a
                              href="#"
                              @click="viewPdf(attachment_list_display[index])"
                              v-if="isPdf(file_name[index]) == true"
                              >
                              {{ file_name[index] }}
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </table>

                  <v-spacer></v-spacer>
                  <b-button
                    type="button"
                    class="ml-2"
                    variant="primary"
                    @click="handleContent('list')"
                  >
                    Tutup
                  </b-button>
                  <b-button
                    type="button"
                    class="ml-2"
                    variant="success"
                    @click="handleContentUpdate(detail.id)"
                    v-if="btnAccess"
                  >
                    Edit
                  </b-button>
                </div>
                <!--end: Wizard Body-->
              </div>
            </div>
          </div>
          <!--akhir::detail-->

          <!-- Konten Tambah -->
          <div v-if="content == 'tambah'">
            <div class="animate__animated animate__fadeIn">
              <h4 class="mb-5">Tambah Materi Baru</h4>
              <form @submit.stop.prevent="materialAdd()">
                <b-form-group
                  id="input-group-title"
                  label="Judul Materi:"
                  label-for="input-title"
                >
                  <b-form-input
                    id="input-title"
                    v-model="post.title"
                    placeholder="Judul Materi"
                  ></b-form-input>
                  <small class="text-danger">{{ error.title }}</small>
                </b-form-group>

                <b-form-group
                  id="input-group-topic-id"
                  label="Topik:"
                  label-for="input-topic-id"
                >
                  <treeselect
                    v-model="post.topic_id"
                    :multiple="false"
                    placeholder="Pilih Topik"
                    :options="topics"
                  />
                  <small class="text-danger">{{ error.topic }}</small>
                </b-form-group>

                <b-form-group id="input-group-content">
                  <label for="input-content"
                    >Kontent/Deskripsi:
                    <em class="text-muted">opsional</em></label
                  >
                  <div class="document-editor">
                    <ckeditor
                      :editor="editor"
                      :config="editorConfig"
                      @ready="onReady"
                      v-model="post.description"
                    ></ckeditor>
                  </div>
                  <small class="text-danger">{{ error.description }}</small>
                </b-form-group>

                <b-form-group
                  id="input-group-file"
                  label="Lampiran:"
                  label-for="input-file"
                >
                  <b-form-file
                    multiple
                    placeholder="Upload Lampiran"
                    drop-placeholder="Lepaskan File disini..."
                    @change="upload"
                  ></b-form-file>
                  <small class="text-danger">{{ error.attachment }}</small>
                </b-form-group>

                <b-button type="submit" variant="primary">Simpan</b-button>
                <b-button
                  type="button"
                  class="ml-2"
                  variant="default"
                  @click="handleContent('list')"
                >
                  Batal
                </b-button>
              </form>
            </div>
          </div>
          <!-- Akhir::Konten Tambah -->

          <!-- Konten Ubah -->
          <div v-if="content == 'ubah'">
            <div class="animate__animated animate__fadeIn">
              <h4 class="mb-5">Ubah Mata Pelajaran</h4>
              <form @submit.stop.prevent="materialUpdate(edit.id)">
                <b-form-group
                  id="input-group-title"
                  label="Judul Materi:"
                  label-for="input-title"
                >
                  <b-form-input
                    id="input-title"
                    v-model="edit.title"
                    placeholder="Judul Materi"
                  ></b-form-input>
                  <small class="text-danger">{{ error.title }}</small>
                </b-form-group>

                <b-form-group
                  id="input-group-topic-id"
                  label="Topik:"
                  label-for="input-topic-id"
                >
                  <treeselect
                    v-model="edit.topic_id"
                    :multiple="false"
                    placeholder="Pilih Topik"
                    :options="topics"
                  />
                  <small class="text-danger">{{ error.topic }}</small>
                </b-form-group>

                <b-form-group id="input-group-content">
                  <label for="input-content"
                    >Kontent/Deskripsi:
                    <em class="text-muted">opsional</em></label
                  >
                  <div class="document-editor">
                    <ckeditor
                      :editor="editor"
                      :config="editorConfig"
                      @ready="onReady"
                      v-model="edit.description"
                    ></ckeditor>
                  </div>
                  <small class="text-danger">{{ error.description }}</small>
                </b-form-group>

                <b-form-group
                  id="input-group-file"
                  label="Lampiran:"
                  label-for="input-file"
                >
                  <b-form-file
                    multiple
                    placeholder="Upload Lampiran"
                    drop-placeholder="Lepaskan File disini..."
                    @change="upload"
                  ></b-form-file>
                  <small class="text-danger">{{ error.attachment }}</small>
                </b-form-group>

                <div
                  v-for="(attachment, index) in attachment"
                  :key="attachment"
                  class="col-md-6"
                >
                  <b-card
                    class="m-2 shadow-sm"
                    v-if="attachment.length >= 1 && attachment[0] != ''"
                  >
                    <b-card-text class="d-flex justify-content-between">
                      <span>{{ file_name[index] }}</span>
                      <span style="cursor: pointer" @click="deleteAttach(index)"
                        >X</span
                      >
                    </b-card-text>
                  </b-card>
                </div>

                <b-button type="submit" variant="primary">Simpan</b-button>
                <b-button
                  type="button"
                  class="ml-2"
                  variant="default"
                  @click="handleContent('list')"
                >
                  Batal
                </b-button>
              </form>
            </div>
          </div>
          <!-- Akhir::Konten Tambah -->
        </div>
      </div>
    </div>
    <!-- pdf preview -->
    <PdfPreview :pdfFile="pdfFile" @closePreview="closePreview" />
  </div>
</template>

<script>
import PdfPreview from "@/view/components/general/PdfPreview.vue"
import ApiService from "@/core/services/api.service.js";
import Swal from "sweetalert2";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import Table from "@/view/components/courses/materials/Table.vue";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    course: Object,
  },
  components: {
    Table,
    PdfPreview
  },
  data() {
    return {
      // data
      materials: [],
      detail: [],
      edit: [],
      formats: [],
      durations: [],
      topics: [],
      formData: new FormData(),
      attachment: "",
      file_name: [],

      attachment_list: [],
      attachment_list_display: [],

      post: {
        title: "",
        description: "",
        topic_id: "",
        purpose: "material-add",
        course_id: "",
      },

      error: {
        title: "",
        description: "",
        topic_id: "",
      },

      // format
      content: null,
      perPage: 20,
      filter: null,
      totalRows: 1,
      currentPage: 1,
      sortData: null,
      sortBy: null,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        { key: "title", label: "Judul", sortable: true },
        {
          key: "action",
          label: "Aksi",
        },
      ],
      user_data: [],
      // ckeditor
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "indent",
          "outdent",
          "|",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      // access
      btnAccess: false,
      // other
      pdfFile: null
    };
  },
  methods: {
    isPdf(filename){
      // console.log('filename = ', filename)
      if(filename != undefined){
        let arrName = filename.split('.')
        return (arrName[arrName.length - 1] === 'pdf') ? true : false
      }
      return false
    },
    viewPdf(pdfFile){
      this.pdfFile = pdfFile
    },
    closePreview(){
      this.pdfFile = null
    },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    // handle action button
    handleContent(content) {
      this.content = content;
      this.file_name = [];
      this.attachment_list_display = [];
      this.attachment_list = [];
      this.formData = new FormData();
    },
    handleContentUpdate(id) {
      this.getTopic();
      this.getMaterialById(id);
      this.content = "ubah";
    },
    async handleContentDetail(id) {
      this.detail = await module.get("api/materials/" + id);
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.content = "list";
      } else {
        this.attachment_list = this.detail.attachment_list.split(",");
        //("attach", this.attachment_list);

        let a;
        for (a = 0; a < this.attachment_list.length; a++) {
          if (this.attachment_list[a]) {
            // console.log('attachment[',a, this.attachment_list[a].split('/'))
            let arrAttachment = this.attachment_list[a].split('/')
            //("true");
            if(arrAttachment[3] === 'storage'){
              this.attachment_list_display.push(this.attachment_list[a]);
            //("attach display", this.attachment_list_display);
            this.file_name.push(this.attachment_list[a].split("____")[1]);
            //("fn", this.file_name);
            }
          }
        }

        //("fn", this.filename);
        this.content = "detail";
      }
    },
    handleAdd() {
      this.getTopic();
      this.content = "tambah";
    },
    resetPostOnNew() {
      let self = this;
      Object.keys(this.post).forEach(function (key, index) {
        if (key === "purpose") {
          self.purpose = "material-add";
        } else {
          self.post[key] = "";
        }
        // //("post", this.post)
        // //("self", self.post)
      });
    },
    resetEditOnNew() {
      let self = this;
      Object.keys(this.edit).forEach(function (key, index) {
        if (key === "formData") {
          self.edit[key] = new FormData();
        } else {
          self.edit[key] = "";
        }
        // //("edit", this.edit)
      });
    },
    upload(event) {
      if (event.target.files.length > 0) {
        this.formData = new FormData();
      }

      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("attachment[]", event.target.files[a]);
      }

      //("attachment", event.target.files);
    },
    deleteAttach(index) {
      //("index", index);
      this.attachment.splice(index, 1);
      this.file_name.splice(index, 1);
      //("attachment_list", this.attachment);
    },

    // system operation
    // async getMaterials() {
    //   let filterParams = ``;
    //   let response = await module.paginate(
    //     `api/materials/my-materials/${getUser().id}`,
    //     `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
    //   );
    //   let pagination = response.meta.pagination;
    //   this.totalRows = pagination.total;

    //   let a, b;
    //   b = this.perPage * (this.currentPage - 1) + 1;
    //   for (a = 0; a < response.data.length; a++) {
    //     response.data[a].number = b + a;
    //   }

    //   this.materials = response.data;
    //   //("jalaniini material baru", this.materials)
    // },

    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.getMaterial();
    },

    async getMaterialById(id) {
      this.edit = await module.get("api/materials/" + id);
      // If Data Not Found
      if (this.edit == null) {
        // Redirect To List
        this.content = "list";
      } else {
        this.edit["_method"] = "put";
        this.detail = this.edit;
        this.attachment = this.edit.attachment_list.split(",");
        //("attachment", this.attachment);

        let b;
        for (b = 0; b < this.attachment.length; b++) {
          this.file_name.push(this.attachment[b].split("____")[1]);
          //("fn", this.file_name);
        }
      }
    },

    // topic
    getTopic() {
      ApiService.get(`api/topics/my-topics/${this.user_data.id}`)
        .then((response) => {
          this.topics = response.data.data;
          for(let a = 0; a < this.topics.length; a++){
            for(let b = 0; b < this.topics[a].children.length; b++){
              delete this.topics[a].children[b].children
            }
          }
          this.topics.unshift({
            label: "Pilih Topik",
            id: "",
            isDisabled: true,
          });
          //("topics ===================================", this.topics);
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },

    async materialAdd() {
        this.post['_method'] = 'put'
        this.post.course_id = this.course.course_id
      for (const [key, value] of Object.entries(this.post)) {
        this.formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(this.formData, `api/curriculums/content/${this.course.id}`);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.resetPostOnNew();
        this.handleContent("list");
        this.$root.$emit("refreshCourseDetail");
        this.$root.$emit("refreshTableCurriculumMaterial");
      }
    },

    async materialUpdate(id) {
      let attachments = this.attachment.join();
      //("attach data", attachments);

      this.formData.append("attachment_list", attachments);

      for (const [key, value] of Object.entries(this.edit)) {
        if (key !== "attachment_list") {
          this.formData.append(key, value);
        }
        // else {
        //   formData.append(key, value);
        // }
        // this.formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(this.formData, "api/materials/" + id);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.resetEditOnNew();
        this.handleContent("list");
        this.$root.$emit("refreshCourseDetail");
        this.$root.$emit("refreshTableCurriculumMaterial");
      }
    },

    async materialDelete(id) {
      // Delete Data
      let result = await module.delete("api/materials/" + id);
      // If Deleted
      if (result) {
        this.getMaterials();
      }
    },

    // materialDelete(id) {
    //   Swal.fire({
    //     title: "Apakah kamu yakin ingin menghapus data ?",
    //     showCancelButton: true,
    //     confirmButtonText: `Delete`,
    //   }).then((result) => {
    //     /* Read more about isConfirmed, isDenied below */
    //     if (result.isConfirmed) {
    //       ApiService.delete("api/materials/" + id)
    //         .then(() => {
    //           Swal.fire("Data berhasil dihapus", "", "success");
    //           this.getMaterials();
    //         })
    //         .catch(() => {
    //           Swal.fire("Data gagal dihapus", "", "error");
    //         });
    //     } else {
    //       Swal.fire("Data batal dihapus", "", "info");
    //     }
    //   });
    // },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "7009") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted() {
    this.content = "list";
    // this.getMaterials();
    this.checkAccess()

    this.user_data = getUser();
  },
  computed: {},
};
</script>

<style scoped>
table .btn {
  margin-right: 5px;
}

table .btn {
  margin-right: 5px;
}

.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
</style>